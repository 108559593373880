import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import {
  AddressApiResult,
  AddressFields,
  AutocompleteResult,
  containsErrors,
  Country,
  DepositIntervenant,
  EventType,
  FieldStatus,
  IntervenantValidator,
  isFilled,
  PersonneFields,
  SelectButton,
  SubmitButton
} from '..'
import Message from '../constants/Message'
import { SirenDenomination } from '../interfaces/OpenDataRNCSInterfaces'
import CheckboxField from '../form/fields/CheckboxField'

interface CoCreatorFormProps extends WrappedComponentProps {
  parentFieldStatus: FieldStatus,
  foDisplay?: boolean | null
  depositCoDepositor: DepositIntervenant,
  idCurrentUser?: string | number,
  handleSelfCoDepositor?: () => void,
  handleResetCoDepositor?: () => void,
  onUdpateCoDepositor: (coDepositor?: DepositIntervenant, fieldStatus?: FieldStatus) => void,
  handleChange?: (event: EventType|EventType[]) => void,
  handleSubmitCoDepositor?: (coDepositor?: DepositIntervenant) => void,
  fillContributorInfo?: (siren: string) => void,
  findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[]|null>,
  isCreation?: boolean|null,
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>,
  getCountries: () => Promise<void | Country[]>,
  intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
  handleSelectIntervenant?: (intervenant: DepositIntervenant) => void,
  intervenantType: string,
  checkIsRecipientNeeded?: boolean
}

const RenderCoDepositorOrApplicantForm: FC<CoCreatorFormProps> = ({
  parentFieldStatus,
  handleChange,
  depositCoDepositor,
  foDisplay,
  idCurrentUser,
  handleSelfCoDepositor,
  handleResetCoDepositor,
  onUdpateCoDepositor,
  handleSubmitCoDepositor,
  fillContributorInfo,
  findListSirenByNamePromise,
  isCreation,
  searchAddress,
  getCountries,
  intervenants,
  handleSelectIntervenant,
  intervenantType,
  checkIsRecipientNeeded = false
}) => {
  const [currentCoDepositor, setCurrentCoDepositor] = useState(depositCoDepositor)
  const [fieldStatus, setFieldStatus] = useState()

  useEffect(() => {
    setCurrentCoDepositor(depositCoDepositor)
  }, [depositCoDepositor])

  useEffect(() => {
    setFieldStatus(parentFieldStatus)
  }, [parentFieldStatus])

  /**
   * Enregistrement des modifications des co-déposants
   * @param event
   */
  const handleChanges = (event: EventType|EventType[]) => {
    if (!Array.isArray(event)) {
      event = [event]
    }
    let coDepositorsPatches = currentCoDepositor

    event.forEach((e: EventType) => {
      coDepositorsPatches = {
        ...coDepositorsPatches,
        [e.target.name]: e.target.value
      }
    })

    setCurrentCoDepositor(IntervenantValidator.cleanIntervenant(coDepositorsPatches))
    if (foDisplay) {
      handleChange && handleChange(coDepositorsPatches)
    }
  }

  /**
   * Vérifie que tous les champs sont remplis
   */
  const validateCoDepositor = () => {
    const fieldStatus = IntervenantValidator.validateIntervenant(currentCoDepositor)
    if (!containsErrors(fieldStatus)) {
      return handleSubmitCoDepositor && handleSubmitCoDepositor(currentCoDepositor)
    } else {
      setFieldStatus(fieldStatus)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header row mx-0 '>
        <h2 className={!foDisplay ? 'h4' : ''}>
          <FormattedMessage id={`intervenant_${intervenantType}_edit`} />
        </h2>
        <div className='col options px-0 my-auto'>

          {isFilled(intervenants) && (
            <SelectButton
              className='btn btn-link text-primary'
              options={intervenants}
              onSelect={handleSelectIntervenant}
            >
              <FormattedMessage id='intervenant_copier_donnees' />
            </SelectButton>
          )}
          {
            foDisplay &&
              <>
                <div className='separator' />
                {currentCoDepositor && idCurrentUser !== currentCoDepositor.idUser && (
                  <button className='btn btn-link text-primary' onClick={handleSelfCoDepositor}>
                    <FormattedMessage id={`intervenant_auto_${intervenantType}`} />
                  </button>
                )}
                {currentCoDepositor && idCurrentUser === currentCoDepositor.idUser && (
                  <button className='btn btn-link text-primary' onClick={handleResetCoDepositor}>
                    <FormattedMessage id='intervenant_reinitialiser' />
                  </button>
                )}
              </>

          }
        </div>
      </div>

      {currentCoDepositor &&
        <>
          <PersonneFields
            onChange={handleChanges}
            intervenant={currentCoDepositor}
            fieldStatus={fieldStatus}
            fillContributorInfo={fillContributorInfo}
            findListSirenByNamePromise={findListSirenByNamePromise}
          />
          {
            checkIsRecipientNeeded &&
              <div className='col-12'>
                <CheckboxField
                  inputId='isRecipient'
                  label={<FormattedMessage id='field_agent_is_recipient' />}
                  checked={currentCoDepositor.isRecipient}
                  onChange={handleChanges}
                  className='mb-0'
                />
                {
                  currentCoDepositor.isRecipient &&
                    <span className='text-warning'>
                      <FormattedMessage id='intervenant_warning_agent_is_recipient' />
                    </span>
                }
              </div>
          }

          <AddressFields
            onChange={handleChanges}
            address={currentCoDepositor.address}
            fieldStatus={fieldStatus}
            searchAddress={searchAddress}
            getCountries={getCountries}
          />
        </>}

      <div className='row mt-4 justify-content-between'>
        {!isCreation &&
          <div className='col-4'>
            <button className='btn btn-block btn-outline-gris' onClick={() => onUdpateCoDepositor()}>
              <FormattedMessage id='button_return' />
            </button>
          </div>}
        <div className='col-4'>
          <SubmitButton className='btn btn-block bg-primary' onClick={() => validateCoDepositor()}>
            <FormattedMessage id={`intervenant_save_${intervenantType}`} />
          </SubmitButton>
        </div>
      </div>
    </IntlProvider>
  )
}

export default RenderCoDepositorOrApplicantForm
