import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

interface PreviewProps {
  file: File|(() => Promise<string>)|string,
  filename?: string,
  className?: string,
  onClick?: () => void,
  refreshAction?: boolean
}

const Preview: FC<PreviewProps> = ({
  file,
  filename,
  className,
  onClick,
  refreshAction
}) => {
  const [preview, setPreview] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const initPreview = (image: string) => {
    setPreview(image)
    setLoading(false)
  }

  useEffect(() => {
    let unmounted = false
    // Création d'une preview de l'image
    setLoading(true)
    setError(false)

    // Si file est une fonction, on fetch l'image
    if (typeof file === 'function') {
      file().then((r) => {
        if (!unmounted) {
          initPreview(r)
        }
      }
      ).catch(() => {
        if (!unmounted) {
          setLoading(false)
          setError(true)
        }
      })

      // Si file est un string, on part du principe que soit c'est une URL
      // soit une image en base 64
    } else if (typeof file === 'string') {
      if (!unmounted) {
        initPreview(file)
      }
      // Sinon on considère que c'est un fichier provenant de l'utilisateur
    } else if (!file.preview) {
      try {
        if (!unmounted) {
          initPreview(URL.createObjectURL(file))
        }
      } catch (error) {}
    } else {
      if (!unmounted) {
        initPreview(file.preview)
      }
    }

    // Suppression de la preview lors de la destruction du composant
    return () => {
      URL.revokeObjectURL(preview)
      unmounted = true
    }
  }, [typeof file, typeof file === 'object' && file.name, filename, refreshAction])

  return loading ? (
    <FontAwesomeIcon icon={faSpinner} className='loader my-auto' />
  ) : (
    error
      ? <FontAwesomeIcon icon={faTimesCircle} className='text-danger my-auto' />
      : <img className={`${className || ''}`} src={preview} alt={filename || file.name || ''} onClick={onClick} />
  )
}

export default Preview
