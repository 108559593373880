import React, { FC } from 'react'
import { FieldProps } from '../FormInterfaces'
import ErrorField from '../errors/ErrorField'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

interface TextAreaProps extends FieldProps {
  required?: boolean,
  rows?: number,
  spellCheck?: boolean,
  maxLength?: string,
  labelClassName?: string,
  infoHelp?: string
}

const TextArea: FC<TextAreaProps> = ({
  inputId,
  label,
  value,
  labelClassName = '',
  className = '',
  fieldStatus = {},
  onChange,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  rows = 3,
  spellCheck = true,
  maxLength,
  infoHelp
}) => (
  <div className='form-group'>
    {label &&
      <div className='d-flex align-items-center'>

        <label className={`form-label ${labelClassName}`} htmlFor={inputId}>
          {label}
          {required && <span className='text-danger'> *</span>}
        </label>
        {infoHelp &&
          <OverlayTrigger
            trigger='click'
            placement='bottom'
            overlay={
              <Popover id='popover-positioned-bottom'>
                <Popover.Content>
                  {infoHelp}
                </Popover.Content>
              </Popover>
            }
            rootClose
            rootCloseEvent='click'
          >
            <span className='cursor-pointer ml-2 text-primary'>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </OverlayTrigger>}
      </div>}
    <textarea
      id={inputId}
      name={inputId}
      className={`form-control ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      rows={rows}
      spellCheck={spellCheck}
      maxLength={maxLength}
    />

    <ErrorField message={fieldStatus[inputId]} />
  </div>
)

export default TextArea
