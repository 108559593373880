import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import { Button } from 'react-bootstrap'
import {
  AddressApiResult,
  AddressFields,
  Country,
  DepositIntervenant,
  EventType,
  IntervenantValidator,
  FieldStatus,
  PersonneFields,
  AutocompleteResult,
  isFilled,
  SelectButton, ManageableQualitySelectField, ManageableQuality
} from '..'
import Message from '../constants/Message'
import { SirenDenomination } from '../interfaces/OpenDataRNCSInterfaces'
import SubtitleIntervenant from './intervenant/SubtitleIntervenant'

interface DepositorFormProps extends WrappedComponentProps {
  idCurrentUser?: number|null,
  foDisplay?: boolean|null,
  boDisplay? : boolean|null,
  handleChange?: (event: EventType|EventType[]) => void,
  handleSelfDepositor?: () => void|null,
  handleResetDepositor?: () => void|null,
  handleDepositorForm?: (depositor: DepositIntervenant) => void|null,
  depositDepositor: DepositIntervenant,
  fieldStatus: FieldStatus,
  fillContributorInfo: (siren: string) => void,
  findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[]|null>
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>,
  getCountries: () => Promise<void | Country[]>,
  intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
  handleSelectIntervenant?: (intervenant: DepositIntervenant) => void,
  intervenantType: string,
  manageableQualities?: ManageableQuality[],
  isEmailRequired?: boolean|undefined
}

const RenderDepositorForm: FC<DepositorFormProps> = ({
  handleChange,
  idCurrentUser,
  foDisplay,
  boDisplay,
  handleSelfDepositor,
  handleResetDepositor,
  handleDepositorForm,
  depositDepositor,
  fieldStatus,
  fillContributorInfo,
  findListSirenByNamePromise,
  searchAddress,
  getCountries,
  intervenants,
  handleSelectIntervenant,
  intervenantType,
  manageableQualities,
  isEmailRequired = false,
  intl
}) => {
  const [currentDepositor, setCurrentDepositor] = useState(depositDepositor)

  useEffect(() => {
    setCurrentDepositor(depositDepositor)
  }, [depositDepositor])

  /**
   * Enregistrement des modifications du déposant
   * @param event
   */
  const handleChanges = (event: EventType|EventType[]) => {
    if (!Array.isArray(event)) {
      event = [event]
    }
    let depositorPatches = currentDepositor

    event.forEach((e: EventType) => {
      depositorPatches = {
        ...depositorPatches,
        [e.target.name]: e.target.value
      }
    })

    setCurrentDepositor(IntervenantValidator.cleanIntervenant(depositorPatches))
    if (foDisplay) {
      handleChange && handleChange(depositorPatches)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header'>
        <div className='d-flex'>
          <h2 className={`mb-0 ${boDisplay && !foDisplay ? 'h4' : ''}`}>
            <FormattedMessage id={`intervenant_onglet_${intervenantType}`} />
          </h2>
          <div className='col options px-0 my-auto'>
            {isFilled(intervenants) && (
              <SelectButton
                className='btn btn-link text-primary'
                options={intervenants}
                onSelect={handleSelectIntervenant}
              >
                <FormattedMessage id='intervenant_copier_donnees' />
              </SelectButton>
            )}
            {
              foDisplay &&
                <>
                  <div className='separator' />
                  {currentDepositor && idCurrentUser !== currentDepositor.idUser && (
                    <button className='btn btn-link text-primary' onClick={handleSelfDepositor}>
                      <FormattedMessage id={`intervenant_auto_${intervenantType}`} />
                    </button>
                  )}
                  {currentDepositor && idCurrentUser === currentDepositor.idUser && (
                    <button className='btn btn-link text-primary' onClick={handleResetDepositor}>
                      <FormattedMessage id='intervenant_reinitialiser' />
                    </button>
                  )}
                </>
            }
          </div>
        </div>
        <SubtitleIntervenant idText={`intervenant_onglet_${intervenantType}_subtitle`} />
      </div>
      <PersonneFields
        onChange={handleChanges}
        intervenant={currentDepositor}
        fieldStatus={fieldStatus}
        fillContributorInfo={fillContributorInfo}
        findListSirenByNamePromise={findListSirenByNamePromise}
        isEmailRequired={isEmailRequired}
      />

      {
        manageableQualities &&
          <div className='col-12 col-md-4 p-0'>
            <ManageableQualitySelectField
              inputId='manageableQuality'
              fieldStatus={fieldStatus}
              value={currentDepositor.manageableQuality}
              onChange={handleChanges}
              label={<FormattedMessage id='field_manageable_qualities_label' />}
              placeholder={intl.formatMessage({ id: 'placeholder_select' })}
              manageableQualities={manageableQualities}
            />
          </div>
      }

      <AddressFields
        onChange={handleChanges}
        address={currentDepositor.address}
        fieldStatus={fieldStatus}
        searchAddress={searchAddress}
        getCountries={getCountries}
      />
      {
        boDisplay &&
          <div className='d-flex justify-content-around mt-2'>
            <Button className='primary' onClick={() => handleDepositorForm && handleDepositorForm(currentDepositor)}>
              <FormattedMessage id='common_validate' />
            </Button>
          </div>
      }
    </IntlProvider>
  )
}

export default RenderDepositorForm
