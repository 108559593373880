import React, { useState, FC, useEffect } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import {
  DEFAULT_INTERVENANT,
  DEPOSIT_TYPE,
  RenderDepositorForm,
  Transaction,
  Deposit,
  INTERVENANT_DEPOSANT,
  INTERVENANT_DECLARANT,
  DepositIntervenant,
  AutocompleteResult,
  PROROGATION_TYPE,
  APPEAL_TYPE,
  INTERVENANT_DEMANDEUR,
  ManageableQuality,
  OFFICIAL_DOCUMENTS_TYPE,
  PERSONNE_MORALE
} from '@inpi-dm/components'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'
import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import { ProrogationFieldStatus } from '../../interfaces/ProrogationInterfaces'
import IntervenantService from '../../services/transaction/IntervenantService'
import HeaderIntervenant from './HeaderIntervenant'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import AddressApiService from '../../services/content/AddressApiService'
import ContentService from '../../services/content/ContentService'
import OpenDataRNCSService from '../../services/content/OpenDataRNCSService'
import StoreService from '../../services/StoreService'

interface DepositorFormProps extends WrappedComponentProps {
  object : Transaction|Deposit,
  fieldStatus: InscriptionFieldStatus| DepositFieldStatus | ProrogationFieldStatus,
  procedureType: string,
  defaultIntervenantlist?: DepositIntervenant[]
}

const DepositorForm : FC<DepositorFormProps> = ({
  object,
  fieldStatus,
  procedureType,
  intl,
  defaultIntervenantlist = []
}) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [idCurrentUser, setIdCurrentUser] = useState(object.depositor?.idUser || -1)
  const [intervenants, setIntervenants] = useState<AutocompleteResult<DepositIntervenant>[]>([])
  const [manageableQualities, setManageableQualities] = useState<ManageableQuality[]>()

  useEffect(() => {
    if (procedureType === APPEAL_TYPE.value || procedureType === OFFICIAL_DOCUMENTS_TYPE.value) {
      ContentService.getManageableQualities().then(r => setManageableQualities(r))
    }
    if (procedureType === OFFICIAL_DOCUMENTS_TYPE.value && !object.depositor && user) {
      // On prérempli l'email avec l'utilisateur courant
      StoreService.changeStore(procedureType, { depositor: { email: user.email } })
    }
  }, [user])

  useEffect(() => {
    // On récupère les intervenants du store
    const flatContributorsTitle = IntervenantService.getFlatContributorsTitle()

    if (defaultIntervenantlist.length > 0 || flatContributorsTitle.length > 0) {
      // Récupération des intervenants a copier
      const listIntervenants = IntervenantService.removeDuplicateIntervenants([...defaultIntervenantlist, ...flatContributorsTitle])
        .map(intervenant => ({
          label: `${intervenant.numNat ? `${intervenant.numNat} - ` : ''}${IntervenantService.getName(intervenant)}`,
          value: intervenant
        }))
      setIntervenants(listIntervenants)
    }
  }, [defaultIntervenantlist])

  /**
   * Enregistrement des données dans le store à la saisie
   * @param updatedDepositor
   * @param isFromSiren
   */
  const handleChange = (updatedDepositor: DepositIntervenant, isFromSiren = false) => {
    const newDepositor = { ...updatedDepositor }
    if (isFromSiren) {
      newDepositor.type = PERSONNE_MORALE.value
    }
    StoreService.changeStore(procedureType, { depositor: newDepositor })
  }

  /**
   * Remplissage du formulaire avec les informations de l'utilisateur connecté
   */
  const handleSelfDepositor = () => {
    if (user) {
      IntervenantService.buildIntervenantFromUser(user).then(intervenantFromUser => {
        const depositor = {
          ...intervenantFromUser,
          id: object.depositor?.id
        }
        StoreService.changeStore(procedureType, { depositor: depositor })
        setIdCurrentUser(user.id)
      })
    }
  }

  /**
   * Remplissage du formulaire avec les informations de l'utilisateur selectionné
   * @param depositor
   */
  const handleSelectDepositor = (depositor: DepositIntervenant) => {
    if (depositor) {
      IntervenantService.validateCountryOfContributor(depositor).then(newDepositor => {
        StoreService.changeStore(procedureType, { depositor: newDepositor })
        setIdCurrentUser(user.id)
      })
    }
  }

  /**
   * Réinitialisation des champs du formulaire
   */
  const handleResetDepositor = () => {
    StoreService.changeStore(procedureType, { depositor: DEFAULT_INTERVENANT })
    setIdCurrentUser(-1)
  }

  /**
   * Récupère le bon intervenant
   */
  const getIntervenantType = () : string => {
    switch (procedureType) {
      case DEPOSIT_TYPE.value :
        return INTERVENANT_DEPOSANT.value
      case PROROGATION_TYPE.value :
        return INTERVENANT_DECLARANT.value
      case APPEAL_TYPE.value:
      case OFFICIAL_DOCUMENTS_TYPE.value:
        return INTERVENANT_DEMANDEUR.value
      default:
        return INTERVENANT_DEPOSANT.value
    }
  }

  return (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={procedureType} />
        <InternalReferenceField
          value={object.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <RenderDepositorForm
        handleSelfDepositor={handleSelfDepositor}
        searchAddress={AddressApiService.searchAddress}
        getCountries={ContentService.getCountries}
        depositDepositor={object.depositor}
        idCurrentUser={idCurrentUser}
        handleResetDepositor={handleResetDepositor}
        handleChange={handleChange}
        fieldStatus={fieldStatus?.depositor ? fieldStatus.depositor : fieldStatus}
        intl={intl}
        fillContributorInfo={(siren) => {
          OpenDataRNCSService.getContributorInfos(siren).then(r => handleChange(r, true))
        }}
        findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
        foDisplay
        intervenants={intervenants}
        handleSelectIntervenant={handleSelectDepositor}
        intervenantType={getIntervenantType()}
        manageableQualities={manageableQualities}
        isEmailRequired={object.procedureType && object.procedureType === OFFICIAL_DOCUMENTS_TYPE.value}
      />
    </div>)
}

export default injectIntl(DepositorForm)
