import React, { FC, useState } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import {
  CheckboxField,
  EventType,
  FieldStatus,
  PersonnePhysiqueFields,
  SelectField,
  PersonneMoraleFields,
  PersonneContactFields,
  PersonneFormationFields,
  PersonMoraleSirenModal,
  ModalComponent
} from '@inpi-dm/components'
import { DepositIntervenant, PERSONNE_TYPES } from '../..'
import PersonneFieldValidator from '../../form/intervenant/validator/PersonneFieldValidator'
import { SirenDenomination } from '../../interfaces/OpenDataRNCSInterfaces'

interface PersonneFieldsProps extends WrappedComponentProps {
  intervenant: DepositIntervenant,
  onChange: (event: EventType) => void,
  fieldStatus?: FieldStatus | any,
  fillContributorInfo?: (siren: string) => void,
  findListSirenByNamePromise?: (companyName: string) => Promise<SirenDenomination[] | null>,
  isEmailRequired?: boolean | undefined
}

const PersonneFields: FC<PersonneFieldsProps> = ({
  intervenant,
  onChange,
  fieldStatus,
  intl,
  fillContributorInfo,
  findListSirenByNamePromise,
  isEmailRequired = false
}) => {
  const [showSirenModal, setShowSirenModal] = useState(false)
  const onSelectPerson = (event: EventType) => {
    onChange(event)
    setShowSirenModal(!PersonneFieldValidator.isPersonneMorale(intervenant))
  }

  return (
    <>
      <div className='header-field'>
        <FormattedMessage id='field_personne_type_label' />
      </div>

      <div className='row'>
        <div className='col-12 col-md-4'>
          <SelectField
            inputId='type'
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            value={intervenant.type || ''}
            options={PERSONNE_TYPES}
            onChange={(e) => onSelectPerson(e)}
            fieldStatus={fieldStatus}
            required
          />
        </div>
        <div className='col-12 col-md-8 d-flex align-items-center'>
          <CheckboxField
            inputId='personFormation'
            label={intl.formatMessage({ id: 'intervenant_personne_physique_formation' })}
            checked={intervenant.personFormation}
            onChange={onChange}
            fieldStatus={fieldStatus}
          />
        </div>
      </div>

      {PersonneFieldValidator.isPersonneMorale(intervenant) && (
        <div className='row'>
          <div className='col-12 col-md-4'>
            <CheckboxField
              inputId='publicLaw'
              label={intl.formatMessage({ id: 'intervenant_personne_morale_publique' })}
              checked={intervenant.publicLaw}
              onChange={onChange}
              fieldStatus={fieldStatus}
            />
          </div>
        </div>
      )}

      {showSirenModal &&
        <ModalComponent
          title={<FormattedMessage id='intervenant_siren_modal' />}
          customContent={() => (
            <PersonMoraleSirenModal
              intervenant={intervenant}
              fieldStatus={fieldStatus}
              onChange={onChange}
              fillContributorInfo={fillContributorInfo}
              handleClose={() => setShowSirenModal(false)}
            />)}
          handleClose={() => setShowSirenModal(false)}
          show={showSirenModal}
          hideFooter
        />}

      {PersonneFieldValidator.isPersonnePhysique(intervenant) && (
        <PersonnePhysiqueFields
          intervenant={intervenant}
          onChange={onChange}
          fieldStatus={fieldStatus}
        />
      )}

      {PersonneFieldValidator.isPersonneMorale(intervenant) &&
        <PersonneMoraleFields
          intervenant={intervenant}
          fieldStatus={fieldStatus}
          onChange={onChange}
          showSiren={PersonneFieldValidator.isPersonneMorale(intervenant)}
          fillContributorInfo={fillContributorInfo}
          findListSirenByNamePromise={findListSirenByNamePromise}
        />}

      {intervenant.personFormation &&
        <PersonneFormationFields
          intervenant={intervenant}
          fieldStatus={fieldStatus}
          onChange={onChange}
        />}

      <PersonneContactFields
        intervenant={intervenant}
        fieldStatus={fieldStatus}
        onChange={onChange}
        isEmailRequired={isEmailRequired}
      />
    </>
  )
}

export default injectIntl(PersonneFields)
