import React, { FC, useEffect, useState } from 'react'
import { DepositIntervenant, PERSONNE_PHYSIQUE, SubmitButton } from '@inpi-dm/components'
import {
  CIVILITE_MONSIEUR,
  Country,
  DepositDocument, Transaction,
  DOCUMENT_TYPES, ETS_ACCOUNT_STATE_ACTIVE, ETS_ACCOUNT_STATE_LABEL,
  INTERVENANT_MANDATAIRE,
  ManageableQuality,
  SelectOption, CIVILITE_MADAME
} from '..'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Message from '../constants/Message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

interface OverviewIntervenantProps {
  intervenant: DepositIntervenant | any,
  deposit?: Transaction | null | undefined,
  type?: SelectOption,
  onScrollTo?: (blockId: string) => void,
  countries: Country[],
  qualities?: ManageableQuality[],
  powerFile?: DepositDocument|null,
  proofFile?: DepositDocument|null,
  connectionRequestDisplay?: boolean|null,
  lightDisplay?: boolean|null,
  canBeBeneficiary?: boolean,
  handleIsBeneficiary?: () => void,
  beneficiaries?: DepositIntervenant[],
  onlyNeedNames?: boolean
}

const OverviewIntervenant: FC<OverviewIntervenantProps> = ({
  intervenant = {},
  type,
  onScrollTo,
  countries,
  qualities,
  powerFile,
  proofFile,
  deposit,
  connectionRequestDisplay,
  lightDisplay,
  canBeBeneficiary = false,
  handleIsBeneficiary,
  beneficiaries,
  onlyNeedNames = false
}) => {
  const address = intervenant?.address || {}
  const [country, setCountry] = useState<Country>()
  const [quality, setQuality] = useState<ManageableQuality>()

  useEffect(() => {
    if (intervenant.manageableQuality && qualities) {
      const q = qualities.find((manageableQuality) => manageableQuality.code === intervenant.manageableQuality)
      if (q) {
        setQuality(q.label)
      } else {
        setQuality(intervenant.manageableQuality)
      }
    }

    if (countries) {
      const c = countries.find(item => item.code === address.country)
      if (c) {
        setCountry(c.label)
      }
    }
  }, [intervenant, countries, qualities])

  /**
   * Auto scroll jusqu'au bloc document
   */
  const showFile = async () => {
    onScrollTo && onScrollTo('documents')
  }

  /**
   * Permet de générer le badge en fonction du status du compte ETS
   * @param state
   */
  const renderBadge = (state: string) => {
    const currentETSAccount = ETS_ACCOUNT_STATE_LABEL.find((stateLabel) => stateLabel.state === state)
    if (currentETSAccount && currentETSAccount.label) {
      return (
        <span className={`${currentETSAccount.className} rounded px-2 color-white badge-action`}>
          <FormattedMessage id={currentETSAccount.label} />
        </span>
      )
    }
    return null
  }

  /**
   * Render le nom, prénom de l'intervenant
   * @param intervenant
   */
  const renderBasicInfos = (intervenant: DepositIntervenant) => (
    <div>
      {intervenant.civilityCode === CIVILITE_MONSIEUR.value ? 'M. '
        : (intervenant.civilityCode === CIVILITE_MADAME.value ? 'Mme ' : '')}
      {intervenant.lastname ? <b className='text-uppercase'>{intervenant.lastname} </b> : ''}
      {intervenant.firstname ? `${intervenant.firstname} ` : ''}
    </div>
  )

  /**
   * @param intervenant
   */
  const truncateCompanyFullName = (intervenant: DepositIntervenant) => {
    return `${intervenant.fullCompanyName?.slice(0, 15)}...`
  }

  /**
   * Render les informations principales de l'intervenant
   * @param intervenant
   */
  const renderPrimaryInfos = (intervenant: DepositIntervenant) => {
    if (intervenant.type === PERSONNE_PHYSIQUE.value) {
      if (intervenant.personFormation) {
        return (
          <div>
            {intervenant.lastname ? <b className='text-uppercase'>{intervenant.lastname} </b> : ''}
            {intervenant.firstname ? <b>{intervenant.firstname}</b> : ''}
            <FormattedMessage id='contributor_pp_person_formation_start' />
            <b className='font-italic text-uppercase'>{intervenant.companyNameFormation}</b>
            <FormattedMessage id='contributor_pp_person_formation_end' />
          </div>
        )
      } else {
        return (
          <div>
            {renderBasicInfos(intervenant)}
            {!onlyNeedNames && (deposit?.file?.agent === intervenant || deposit?.agent === intervenant) && intervenant.companyName &&
              <b title={intervenant.companyName} className='text-uppercase'>
                {intervenant.companyName}
              </b>}
          </div>
        )
      }
    } else {
      return (
        <div>
          {intervenant.personFormation &&
            <div>
              <FormattedMessage id='contributor_pm_person_formation_start' />
              <b className='text-uppercase'>{intervenant.companyName} </b>
              <FormattedMessage id='contributor_pp_person_formation_start' />
              <b className='font-italic text-uppercase'>{intervenant.companyNameFormation}</b>
              <FormattedMessage id='contributor_pp_person_formation_nature' />
              <b>{intervenant.legalFormFormation}</b>
              <FormattedMessage id='contributor_pp_person_formation_end' />
            </div>}
          <div>
            {renderBasicInfos(intervenant)}
            <b title={intervenant.fullCompanyName || intervenant.companyName} className='text-uppercase'>
              {intervenant.fullCompanyName ? truncateCompanyFullName(intervenant) : intervenant.companyName}
            </b>
            {intervenant.legalForm ? ` - ${intervenant.legalForm}` : ''}
            {intervenant.siren ? ` - ${intervenant.siren}` : ''}
          </div>
        </div>
      )
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      {
        typeof (intervenant) === 'string' ? ( // Pour l'affichage des créateurs
          <div className='mt-1'>{intervenant}</div>
        ) : !(intervenant instanceof Array)
          ? (
            <div className='row'>
              <div className={canBeBeneficiary ? 'col-8' : 'col'}>
                <div>
                  {renderPrimaryInfos(intervenant)}
                  {address.label && !onlyNeedNames &&
                    <div>
                      {address.building ? `${address.building}, ` : ''}
                      {address.label}
                      {address.complement ? `, ${address.complement}` : ''}
                      {`, ${address.zipCode} ${address.city}`}
                      {country ? `, ${country}` : ''}
                    </div>}
                  {!onlyNeedNames && (
                    <div>
                      <div>{intervenant.email && `${intervenant.email}`}</div>
                      <div>{intervenant.phone && `${intervenant.phone}`}</div>
                    </div>
                  )}
                  {!lightDisplay &&
                    <div className={`${connectionRequestDisplay ? 'mt-3' : ''}`}>
                      {quality &&
                        <div>
                          {connectionRequestDisplay && <span className='font-weight-bold'><FormattedMessage id='sso_account_quality' /></span>}
                          {quality}
                        </div>}
                      {intervenant.inscriptionNumber &&
                        <div>
                          {
                            <span className={connectionRequestDisplay && 'font-weight-bold'}>
                              <FormattedMessage id='sso_account_inscription_number' />
                            </span>
                          }
                          {intervenant.inscriptionNumber}
                        </div>}
                    </div>}
                  {connectionRequestDisplay &&
                    <div className={`${connectionRequestDisplay ? 'mt-3' : ''}`}>
                      {intervenant.isRecipient && <FormattedMessage id='sso_account_is_recipient' />}
                      {intervenant.idRecord &&
                        <div>
                          <a
                            href={`/dossiers/${intervenant.idRecord}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <FormattedMessage id='sso_account_link_record' />
                          </a>
                        </div>}
                    </div>}
                  {powerFile &&
                    <>
                      <a className='text-primary cursor-pointer text-' onClick={() => showFile()}><u><FormattedMessage id='intervenant_show_power_file' /></u></a>
                    </>}
                  {proofFile &&
                    <>
                      {powerFile && <br />}
                      <a className='text-primary cursor-pointer' onClick={() => showFile()}><u><FormattedMessage id='intervenant_show_proof_file' /></u></a>
                    </>}
                  {
                    deposit &&
                    type === INTERVENANT_MANDATAIRE &&
                    deposit.documents?.find(doc => doc.type === DOCUMENT_TYPES.DEPOSIT_AGENT_DOCUMENT && doc.internalName !== '') &&
                      <a className='text-primary cursor-pointer' onClick={() => showFile()}><FormattedMessage id='intervenant_show_power_file' /></a>
                  }
                </div>
                {
                  (lightDisplay || connectionRequestDisplay) && renderBadge(lightDisplay ? ETS_ACCOUNT_STATE_ACTIVE : intervenant.state)
                }
                <div>{intervenant.isRecipient && !intervenant.state &&
                  <span className='text-secondary'><FormattedMessage id='intervenant_warning_is_recipient' /></span>}
                </div>

              </div>
              {
                canBeBeneficiary &&
                (!beneficiaries || beneficiaries?.filter(benef => benef.idFromBeneficiary === intervenant.id).length === 0) &&
                  <div className='col'>
                    <SubmitButton
                      className='btn-link-primary font-weight-bold'
                      onClick={() => {
                        handleIsBeneficiary && handleIsBeneficiary()
                      }}
                    >
                      <FontAwesomeIcon icon={faStar} />
                      <FormattedMessage id='contributor_add_beneficiary' />
                    </SubmitButton>
                  </div>
              }
            </div>
          ) : null
      }
    </IntlProvider>
  )
}

export default OverviewIntervenant
