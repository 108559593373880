import React, { FC, useState, MutableRefObject, useEffect } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  CardBlock,
  Preview,
  ReproductionLightDeposit,
  ModelDeposit,
  Country,
  STATUS_WITHDRAWN, REPRODUCTION_STATE_OPTIONS,
  SubmitButton,
  DropArea,
  DepositDocument, ModalComponent, DOCUMENT_TYPES, DateUtils, containsErrors, FieldStatus, Transaction,
  STATUS_DEPOSIT,
  DepositFile, MODEL_CLOSE, MODEL_OPEN
} from '@inpi-dm/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown, faExchangeAlt, faEdit, faTrashAlt, faExclamationTriangle, faSearchPlus } from '@fortawesome/free-solid-svg-icons'
import { DepositPriorityOptions } from '../../../../interfaces/DepositInterfaces'
import { BLACK_AND_WHITE, COLORS } from '../../../../constants/DepositConstants'
import DepositService from '../../../../services/transaction/DepositService'
import TransactionService from '../../../../services/transaction/TransactionService'
import PreviewAndTrashRectificatif from './PreviewAndTrashRectificatif'
import { toast } from 'react-toastify'
import NotificationService from '../../../../services/transaction/NotificationService'
import RecordService from '../../../../services/record/RecordService'
/* global FormData */

interface ContextAwareToggleProps {
  eventKey: string,
}

const ContextAwareToggle : FC<ContextAwareToggleProps> = ({ eventKey }) => {
  const isCurrentEventKey = eventKey === MODEL_OPEN

  return (
    <div className={isCurrentEventKey ? 'mr-1' : 'mr-2'}>
      <FontAwesomeIcon icon={isCurrentEventKey ? faChevronDown : faChevronRight} />
    </div>
  )
}

interface OverviewModelsProps {
  models?: ModelDeposit[],
  depositId:string,
  priorities?: DepositPriorityOptions[],
  countries?: Country[]
  documents?: DepositDocument[],
  setReload?: () => {}
  hasReproRegularization : boolean,
  isEditable? : boolean,
  setIsEditable? : (value: boolean)=>void,
  changeModel?: (index: number) => void,
  deleteModel?: (index: number) => void,
  fieldStatus?: FieldStatus,
  nbCurrent?: number,
  isBeforeForm?: boolean,
  transaction: Transaction,
  notificationReference?: MutableRefObject<null>|null,
  depositView:boolean,
  toggle: string
}

const OverviewModels: FC<OverviewModelsProps> = ({
  models = [],
  depositId,
  priorities,
  countries = [],
  documents,
  setReload,
  hasReproRegularization,
  isEditable,
  setIsEditable,
  changeModel,
  deleteModel,
  fieldStatus,
  nbCurrent,
  isBeforeForm,
  transaction,
  notificationReference,
  depositView,
  toggle
}) => {
  const [fileToAdd, setfileToAdd] = useState<DepositDocument[]>([])

  const [showReproModal, setShowReproModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState()
  const [fileToShow, setFileToShow] = useState()
  const [fileToDelete, setFileToDelete] = useState<DepositDocument[]>([])
  const [supportDocDelete, setSupportDocDelete] = useState(false)
  const [isOpen, setIsOpen] = useState<string[]>([])
  const intl = useIntl()

  // Par défault tout le monde fermé
  useEffect(() => {
    setIsOpen(models.map(() => MODEL_CLOSE))
  }, [])

  useEffect(() => {
    setIsOpen(models.map(() => toggle))
  }, [toggle])

  const toogleCardModel = (indexModel:number) => {
    const newOpen = [...isOpen]
    newOpen[indexModel] = newOpen[indexModel] === MODEL_OPEN ? MODEL_CLOSE : MODEL_OPEN
    setIsOpen(newOpen)
  }

  /**
   * Ajout du document dans le dépot
   */
  const addFileToDocuments = async () => {
    for (const file of fileToAdd) {
      await TransactionService.postDocumentFile(depositId, DOCUMENT_TYPES.DOCUMENT_TYPE_REGULARIZE_REPRO, file.file, file.srcName)
    }
  }

  useEffect(() => setFileToDelete([]), [transaction])
  /**
   * Permet d'auto scroll vers le bloc notification
   */
  const scrollToNotification = () => {
    if (notificationReference && notificationReference.current) {
      window.scrollTo({
        top: notificationReference.current.offsetParent.offsetParent.offsetTop,
        behavior: 'smooth'
      })
    }
  }

  const submitRegularisation = () => {
    if (fileToAdd && fileToAdd.length > 0) {
      return addFileToDocuments().then(() => {
        toast.success(intl.formatMessage({ id: 'overview_deposit_regularize_reproductions_save' }))
        setIsEditable(false)
        setfileToAdd([])
        setReload && setReload()
        scrollToNotification()
      })
    } else {
      setIsEditable(false)
      setfileToAdd([])
      return scrollToNotification()
    }
  }

  /**
   * Supprime le document
   * @param elemToDelete
   */
  const deleteOneFile = (elemToDelete : DepositDocument) => {
    const newFile = [...fileToAdd].filter(element => element !== elemToDelete)
    setFileToDelete([...fileToDelete, elemToDelete])
    setfileToAdd(newFile)
    if (transaction.notifications) {
      transaction.notifications.forEach(async (notification) => {
        const notifToDelete = notification.supportingDocuments?.find(doc => doc.internalName === elemToDelete.internalName)
        if (notifToDelete) {
          setSupportDocDelete(!supportDocDelete)
          return await NotificationService.deleteNotificationFile(depositId, notification.id, elemToDelete.internalName)
        }
      })
    }
  }

  /**
   * Permet d'afficher les reproductions en preview
   */
  const previewReproduction = () => {
    if (fileToShow) {
      if (fileToShow.internalName) {
        // C'est un document
        return (
          <div className='w-100 d-flex'>
            <Preview
              className='m-auto img-bopi'
              file={() => DepositService.resizeDocument(depositId, fileToShow.internalName)}
            />
          </div>
        )
      } else {
        // C'est une image importé par l'utilisateur
        const formData = new FormData()
        formData.append('file', fileToShow.file)
        return (
          <div className='w-100 d-flex'>
            <Preview
              className='m-auto img-bopi'
              file={() => DepositService.resizeFile(formData)}
            />
          </div>
        )
      }
    } else {
      return <></>
    }
  }

  /**
   * Vérifie si une reproduction est 'reliée' a un document dans le dépot
   */
  const hasExchangeRepro = (oneRepro : ReproductionLightDeposit) => {
    return documents?.filter(element => element.srcName === oneRepro.file.internalName) &&
    documents?.filter(element => element.srcName === oneRepro.file.internalName).length > 0
  }

  /**
   * Vérifie si une reproduction est 'reliée' a un document localement
   */
  const hasExchangeReproToAdd = (oneRepro : ReproductionLightDeposit) => {
    return fileToAdd.filter(element => element.srcName === oneRepro.file.internalName).length > 0
  }

  /**
   *
   * Affiche une modale contenant la reproduction en grand
   * @param oneModel
   * @param oneRepro
   */
  const showPreview = (oneModel: ModelDeposit, oneRepro: ReproductionLightDeposit) => {
    setShowPreviewModal({
      model: oneModel,
      repro: oneRepro
    })
  }

  /**
   * Affiche la reproduction
   * @param idDeposit
   * @param idModel
   * @param file
   */
  const getReproduction = (idDeposit: string, idModel: string, file: DepositFile) => {
    if (depositView) {
      return () => RecordService.getReproduction(idDeposit, idModel, file)
    } else {
      return () => DepositService.getReproduction(idDeposit, idModel, file.internalName)
    }
  }

  const displayModel = (oneModel: ModelDeposit, indexModel: number) => {
    const withdrawedReproductions = oneModel.reproductions?.filter(repro => repro.state === STATUS_WITHDRAWN) || []
    return (
      <Accordion key={indexModel} activeKey={isOpen[indexModel]}>
        <Card key={oneModel.id} className='h-auto'>
          {nbCurrent === undefined ? (indexModel !== 0 && <div className='border-top w-100 mt-3' />) : <div className='border-top w-100 mt-3' />}
          <div className='row'>
            <Accordion.Toggle
              as={Card.Header} className='d-flex align-items-center bg-transparent cursor-pointer col-11'
              variant='link' onClick={() => toogleCardModel(indexModel)} eventKey={MODEL_OPEN}
            >
              <div className='d-flex align-items-center mr-5'>
                <ContextAwareToggle eventKey={isOpen[indexModel]} />
                {
                  depositId && oneModel.reproductions[0]?.thumbnail &&
                    <Preview
                      file={getReproduction(depositId, oneModel.id, oneModel.reproductions[0]?.thumbnail)}
                      className=''
                    />
                }
                {
                  depositId && oneModel.reproductions[0]?.url &&
                    <Preview
                      file={oneModel.reproductions[0]?.url}
                      className='image-with-thumbnail'
                    />
                }
              </div>
              <div>
                <div>
                  {
                    fieldStatus && fieldStatus.models && containsErrors(fieldStatus.models[indexModel]) &&
                      <div className='text-danger mr-3'>
                        <FontAwesomeIcon className='mr-1' icon={faExclamationTriangle} />
                        <FormattedMessage id='error_global_models' />
                      </div>
                  }
                  <FormattedMessage id='model_deposit_form_number' />{indexModel + 1}
                  <span className='normal text-lowercase'> ({oneModel.reproductions && oneModel.reproductions.length} <FormattedMessage id='request_number_reproduction' />
                    {oneModel.reproductions.length > 1 ? 's' : ''})
                  </span>
                  {withdrawedReproductions.length > 0 &&
                    <span className='normal text-lowercase text-warning'>
                      ({withdrawedReproductions.length} <FormattedMessage id='reproduction_withdrawn' />{withdrawedReproductions.length > 1 ? 's' : ''})
                    </span>}
                </div>
                {oneModel.priorityId && countries.length > 0 ? (
                  <div>
                    <FormattedMessage id='overview_deposit_priority_title' />
                    {DepositService.getModelPriorityName(oneModel.priorityId, countries, priorities)}
                  </div>
                ) : ''}
                <div className='normal'>
                  {oneModel.modelDesignation && oneModel.modelDesignation.ref
                    ? `${oneModel.modelDesignation.ref}. ${oneModel.modelDesignation.name}`
                    : `${oneModel.modelDesignation.name}`}
                </div>
              </div>
            </Accordion.Toggle>
            {
              changeModel && deleteModel &&
                <div className='col-1 d-flex flex-row justify-content-end align-items-center'>
                  <SubmitButton
                    className='btn-icon-primary mr-3'
                    onClick={() => changeModel(indexModel)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </SubmitButton>
                  <SubmitButton
                    className='btn-icon-danger mr-3'
                    onClick={() => deleteModel(indexModel)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </SubmitButton>
                </div>
            }
          </div>
          <Accordion.Collapse eventKey={MODEL_OPEN}>
            <Card.Body className='p-0 float-right col-11'>
              {
                oneModel.reproductions &&
              oneModel.reproductions.map((oneRepro : ReproductionLightDeposit, index) => {
                const stateOption = REPRODUCTION_STATE_OPTIONS.find(state => state.value === oneRepro.state)
                return (
                  <CardBlock
                    key={index}
                    className={`m-3 ${(stateOption?.borderClassName && !nbCurrent) || ''}`}
                    bodyClassName='p-3'
                    shadow
                  >
                    <div className='d-flex w-100 align-items-center'>
                      <div className='image-with-thumbnail'>
                        {
                          depositId && oneRepro.thumbnail &&
                            <Preview
                              file={getReproduction(depositId, oneModel.id, oneRepro.thumbnail)}
                            />
                        }
                        {
                          depositId && oneRepro.url &&
                            <Preview
                              file={oneRepro.url}
                              className='image-with-thumbnail'
                            />
                        }
                      </div>
                      <div className={`${isEditable ? 'col-4' : 'col-8'} d-flex flex-column justify-content-center`}>
                        <span className='font-weight-bold'>
                          {`${indexModel + 1}.${index + 1} `}
                          {oneRepro?.label || '--'}
                        </span>
                        <span className='font-weight-normal text-normalcase'>
                          {oneRepro?.color === COLORS && <FormattedMessage id='reproductions_form_radio_colors' />}
                          {oneRepro?.color === BLACK_AND_WHITE && <FormattedMessage id='reproductions_form_radio_no_colors' />}
                          {!oneRepro?.color && '--'}
                          {stateOption && ` - ${stateOption.label}`}
                          {oneRepro.dateState && ` ${DateUtils.formatDateFr(oneRepro.dateState)}`}
                        </span>
                        <span className='font-weight-normal text-normalcase'>
                          {oneRepro?.description}
                        </span>
                      </div>
                      {!isEditable && transaction.status && transaction.status !== STATUS_DEPOSIT.DRAFT &&
                          oneRepro.preview &&
                            <div className='col-2 d-flex justify-content-center'>
                              <SubmitButton
                                className='btn-icon-primary'
                                onClick={() => showPreview(oneModel, oneRepro)}
                              >
                                <FontAwesomeIcon icon={faSearchPlus} />
                              </SubmitButton>
                            </div>}
                      {
                        isEditable &&
                        !hasExchangeRepro(oneRepro) &&
                        !hasExchangeReproToAdd(oneRepro) &&
                          <div className='col-6 my-n4'>
                            <DropArea
                              icon={faExchangeAlt}
                              accept={process.env.REACT_APP_ACCEPTED_IMAGE_FORMAT}
                              maxSize={process.env.REACT_APP_ACCEPTED_IMAGE_SIZE}
                              onDrop={(file: File[]) => {
                                const theFile = file[0]
                                setfileToAdd(fileToAdd => [...fileToAdd, {
                                  srcName: oneRepro.file.internalName,
                                  file: theFile
                                }])
                              }}
                            />
                          </div>
                      }
                      {
                        isEditable &&
                        !hasExchangeRepro(oneRepro) &&
                        hasExchangeReproToAdd(oneRepro) &&
                          <div className='row align-items-center'>
                            <FontAwesomeIcon
                              icon={faExchangeAlt}
                              className='text-primary mr-5'
                            />
                            {
                              fileToAdd.filter(element => element.srcName === oneRepro.file.internalName)
                                .map((file, index) => {
                                  const formData = new FormData()
                                  formData.append('file', file.file)
                                  return (
                                    <PreviewAndTrashRectificatif
                                      key={index}
                                      loadFile={() => DepositService.resizeFile(formData)}
                                      onClick={() => {
                                        setShowReproModal(true)
                                        setFileToShow(file)
                                      }}
                                      onTrashClick={() => deleteOneFile(file)}
                                      refreshAction={supportDocDelete}
                                    />
                                  )
                                })
                            }
                          </div>
                      }
                      {
                        isEditable &&
                        !hasExchangeReproToAdd(oneRepro) &&
                        hasExchangeRepro(oneRepro) &&
                          <div className='row align-items-center'>
                            <FontAwesomeIcon
                              icon={faExchangeAlt}
                              className='text-primary mr-5'
                            />
                            {
                              documents.filter(element => element.srcName === oneRepro.file.internalName)
                                .map((file, index) =>
                                  <PreviewAndTrashRectificatif
                                    key={index}
                                    loadFile={() => TransactionService.getDocumentFile(depositId, file.internalName, true)}
                                    onClick={() => {
                                      setShowReproModal(true)
                                      setFileToShow(file)
                                    }}
                                    onTrashClick={() => {
                                      TransactionService.deleteDocumentFile(depositId, file.internalName)
                                      deleteOneFile(file)
                                      setReload && setReload()
                                    }}
                                    refreshAction={supportDocDelete}
                                  />
                                )
                            })
                            }
                          </div>
                      }
                    </div>
                  </CardBlock>
                )
              })
              }
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }

  return (
    <>
      {
        isBeforeForm && nbCurrent !== undefined &&
      models && models.map((oneModel: ModelDeposit, indexModel: number) => indexModel < nbCurrent && displayModel(oneModel, indexModel))
      }
      {
        !isBeforeForm && nbCurrent !== undefined &&
      models && models.map((oneModel: ModelDeposit, indexModel: number) => indexModel > nbCurrent && displayModel(oneModel, indexModel))
      }
      {
        nbCurrent === undefined &&
      models && models.map((oneModel: ModelDeposit, indexModel: number) => displayModel(oneModel, indexModel))
      }
      {
        isEditable &&
          <div>
            <DropArea
              text='overview_deposit_regularize_add_reproductions'
              accept={process.env.REACT_APP_ACCEPTED_IMAGE_FORMAT}
              maxSize={process.env.REACT_APP_ACCEPTED_IMAGE_SIZE}
              onDrop={(file: File[]) => {
                const files = []
                file.forEach(f => {
                  files.push({ file: f })
                })
                setfileToAdd(fileToAdd => [...fileToAdd, ...files])
              }}
            />
            <div className='d-flex align-items-center'>
              {
            documents?.filter(doc => !fileToDelete.includes(doc)).map((file, index) =>
              <PreviewAndTrashRectificatif
                key={index}
                loadFile={() => TransactionService.getDocumentFile(depositId, file.internalName, true)}
                onClick={() => {
                  setShowReproModal(true)
                  setFileToShow(file)
                }}
                onTrashClick={() => {
                  deleteOneFile(file)
                  setReload && setReload()
                }}
                refreshAction={supportDocDelete}
              />
          )
              }
              {
                fileToAdd.map((file, index) => {
                  return (
                    <PreviewAndTrashRectificatif
                      key={`to-add-${index}`}
                      loadFile={file.file}
                      onClick={() => {
                        setShowReproModal(true)
                        setFileToShow(file)
                      }}
                      onTrashClick={() => deleteOneFile(file)}
                      refreshAction={supportDocDelete}
                    />
                  )
                })
              }
            </div>
          </div>

      }
      {
        hasReproRegularization && isEditable &&
          <div className='d-flex justify-content-end'>
            <SubmitButton
              className='btn-outline-primary mr-3'
              onClick={() => {
                setfileToAdd([])
                setIsEditable(false)
              }}
            >
              <FormattedMessage id='button_cancel' />
            </SubmitButton>
            <SubmitButton
              className='btn-primary'
              onClick={submitRegularisation}
            >
              <FormattedMessage id='button_validate' />
            </SubmitButton>
          </div>
      }
      <ModalComponent
        title={<div className='text-capitalize'><FormattedMessage id='request_number_reproduction' /></div>}
        show={showReproModal}
        customContent={previewReproduction}
        handleClose={() => setShowReproModal(false)}
        onClick={() => setShowReproModal(false)}
        size='xl'
      />
      {showPreviewModal &&
        <ModalComponent
          title={<FormattedMessage id='modal_repro_preview' />}
          customContent={() => (
            <div className='text-center mb-3'>
              <Preview
                className='img-bordered'
                key={`preview-modal-${showPreviewModal.model.id}`}
                file={getReproduction(transaction.id, showPreviewModal.model.id, showPreviewModal.repro.preview)}
                filename={showPreviewModal.repro.preview.name}
              />
            </div>
          )}
          size='xl'
          handleClose={() => setShowPreviewModal()}
          show={showPreviewModal}
          hideFooter
        />}
    </>
  )
}

export default OverviewModels
