import store from '../../../../../store/store'
import { storeDepositFieldStatusUpdate } from '../../../../../store/deposit/depositActions'
import { containsErrors, IntervenantValidator } from '@inpi-dm/components'

class CoDepositorValidator {
  validateOnChangeStep = () => {
    const deposit = store.getState().deposit
    const coDepositors = deposit.coDepositors || []
    let hasNoError = true

    const allFieldStatus = coDepositors.map(coDepositor => {
      const fieldStatus = IntervenantValidator.validateIntervenant(coDepositor)
      hasNoError = hasNoError && !containsErrors(fieldStatus)
      return fieldStatus
    })

    store.dispatch(storeDepositFieldStatusUpdate({
      coDepositors: allFieldStatus
    }))

    return hasNoError
  }
}

export default new CoDepositorValidator()
