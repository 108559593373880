import React, { FC, useState, useEffect } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import {
  AutocompleteResult,
  containsErrors,
  DEFAULT_INTERVENANT,
  Deposit,
  DEPOSIT_TYPE,
  DepositIntervenant,
  FieldStatus,
  INSCRIPTION_TYPE,
  INTERVENANT_APPLICANTS,
  INTERVENANT_CO_DECLARANT,
  INTERVENANT_CO_DEPOSANT,
  INTERVENANT_OTHER_APPLICANTS,
  IntervenantValidator,
  isFilled,
  PERSONNE_PHYSIQUE,
  PROROGATION_TYPE,
  RenderCoDepositorOrApplicantForm,
  Transaction
} from '@inpi-dm/components'
import IntervenantService from '../../services/transaction/IntervenantService'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import OpenDataRNCSService from '../../services/content/OpenDataRNCSService'
import { sirenValidity } from '@inpi-dm/components/src/utils/validationUtils'
import AddressApiService from '../../services/content/AddressApiService'
import ContentService from '../../services/content/ContentService'
import HeaderIntervenant from './HeaderIntervenant'

interface ApplicantFormProps extends WrappedComponentProps {
  object : Transaction|Deposit,
  procedureType: string,
  initialApplicant?: DepositIntervenant,
  initialFieldStatus?: FieldStatus,
  onUpdateApplicant: (applicant?: DepositIntervenant, fieldStatus?: FieldStatus) => void,
  isOtherApplicant: boolean,
  defaultIntervenantlist?: DepositIntervenant[]
}

const ApplicantForm: FC<ApplicantFormProps> = ({
  intl,
  object,
  procedureType,
  initialApplicant = DEFAULT_INTERVENANT,
  initialFieldStatus = {},
  onUpdateApplicant,
  isOtherApplicant,
  defaultIntervenantlist = []
}) => {
  const [applicant, setApplicant] = useState(initialApplicant)
  const [fieldStatus, setFieldStatus] = useState(initialFieldStatus)
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [idCurrentUser, setIdCurrentUser] = useState<string | number>(applicant?.idUser || -1)
  const [intervenants, setIntervenants] = useState<AutocompleteResult<DepositIntervenant>[]>([])

  useEffect(() => {
    if (containsErrors(initialFieldStatus)) {
      setFieldStatus(initialFieldStatus)
    }
  }, [initialFieldStatus])

  useEffect(() => {
    // Récupération des intervenants a copier
    const listIntervenants = IntervenantService.removeDuplicateIntervenants(defaultIntervenantlist)
      .map(intervenant => ({
        label: `${intervenant.numNat ? `${intervenant.numNat} - ` : ''}${IntervenantService.getName(intervenant)}`,
        value: intervenant
      }))
    setIntervenants(listIntervenants)
  }, [defaultIntervenantlist])

  /**
   * Enregistrement des données saisies dans le co-déposant ou demandeur du state courant
   * @param updatedApplicant
   */
  const handleChange = (updatedApplicant: DepositIntervenant) => {
    setApplicant({ ...applicant, ...updatedApplicant })
  }

  /**
   * Remplissage du formulaire avec les informations de l'utilisateur connecté
   */
  const handleSelfApplicant = () => {
    if (user) {
      IntervenantService.buildIntervenantFromUser(user).then(updatedApplicant => {
        setApplicant({
          ...updatedApplicant,
          id: applicant?.id
        })
        setIdCurrentUser(user.id)
      })
    }
  }

  /**
   * Réinitialisation des champs du formulaire
   */
  const handleResetApplicant = () => {
    setApplicant(initialApplicant)
    setIdCurrentUser(initialApplicant?.idUser || -1)
  }

  const handleSubmitApplicant = () => {
    let updatedFieldStatus = IntervenantValidator.validateIntervenant(applicant)
    // Si le co déposant/demandeur est une personne physique ou qu'il n'a pas de siren ou que le siren n'est pas renseigné
    // car pas obligatoire ou que le siren est déjà en erreur on fait une validation simple sinon on vérifie la validité du siren
    if (applicant.type !== PERSONNE_PHYSIQUE.value && applicant.siren && isFilled(applicant.siren) && !isFilled(updatedFieldStatus.siren)) {
      const errorMessage = sirenValidity(applicant)
      if (errorMessage) {
        updatedFieldStatus = {
          ...updatedFieldStatus,
          siren: intl.formatMessage({ id: errorMessage })
        }
      }
    }
    validateApplicant(updatedFieldStatus)
  }

  /**
   * Remplissage du formulaire avec les informations de l'utilisateur selectionné
   * @param intervenant
   */
  const handleSelectIntervenant = (intervenant: DepositIntervenant) => {
    if (intervenant) {
      IntervenantService.validateCountryOfContributor(intervenant).then(newIntervenant => {
        setApplicant(newIntervenant)
      })
    }
  }

  const validateApplicant = (updatedFieldStatus: FieldStatus) => {
    if (!containsErrors(updatedFieldStatus)) {
      onUpdateApplicant(applicant, updatedFieldStatus)
    } else {
      setFieldStatus(updatedFieldStatus)
    }
  }

  /**
   * récupère le bon type d'intervenant
   */
  const getIntervenantType = (): string => {
    if (procedureType === DEPOSIT_TYPE.value) {
      return INTERVENANT_CO_DEPOSANT.value
    } else if (procedureType === INSCRIPTION_TYPE.value && !isOtherApplicant) {
      return INTERVENANT_APPLICANTS.value
    } else if (procedureType === INSCRIPTION_TYPE.value && isOtherApplicant) {
      return INTERVENANT_OTHER_APPLICANTS.value
    } else if (procedureType === PROROGATION_TYPE.value) {
      return INTERVENANT_CO_DECLARANT.value
    } else {
      return INTERVENANT_APPLICANTS.value
    }
  }

  return (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={procedureType} />
        <InternalReferenceField
          value={object.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <RenderCoDepositorOrApplicantForm
        depositCoDepositor={applicant}
        idCurrentUser={idCurrentUser}
        handleSelfCoDepositor={handleSelfApplicant}
        handleResetCoDepositor={handleResetApplicant}
        onUdpateCoDepositor={onUpdateApplicant}
        handleSubmitCoDepositor={handleSubmitApplicant}
        handleChange={handleChange}
        parentFieldStatus={fieldStatus}
        searchAddress={AddressApiService.searchAddress}
        getCountries={ContentService.getCountries}
        foDisplay
        intl={intl}
        fillContributorInfo={(siren) => {
          OpenDataRNCSService.getContributorInfos(siren).then(handleChange)
        }}
        findListSirenByNamePromise={(name: string) => OpenDataRNCSService.findListSirenByName(name)}
        intervenantType={getIntervenantType()}
        intervenants={intervenants}
        handleSelectIntervenant={handleSelectIntervenant}
      />
    </div>)
}

export default injectIntl(ApplicantForm)
