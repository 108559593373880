import Message from './Message'
import { DepositIntervenant } from '..'

export const FRANCE = 'FR'

export const PERSONNE_MORALE = {
  value: 'M',
  label: Message.personne_type_morale
}

export const PERSONNE_PHYSIQUE = {
  value: 'P',
  label: Message.personne_type_physique
}

export const PERSONNE_TYPES = [
  PERSONNE_PHYSIQUE,
  PERSONNE_MORALE
]

export const CIVILITE_MONSIEUR = {
  value: 'MR',
  label: Message.civilite_monsieur
}

export const CIVILITE_MADAME = {
  value: 'MS',
  label: Message.civilite_madame
}

export const CIVILITES = [
  CIVILITE_MONSIEUR,
  CIVILITE_MADAME
]

export const DEFAULT_INTERVENANT: DepositIntervenant = {
  type: PERSONNE_PHYSIQUE.value,
  civilityCode: CIVILITE_MONSIEUR.value
}

export const STATUS_DEPOSIT = {
  /** Brouillon */
  DRAFT: 'draft',
  /** Examen de recevabilité */
  CHECK_ACCEPTABILITY: 'check_acceptability',
  /** Irrecevable */
  NOT_ACCEPTED: 'not_accepted',
  /** A attribuer */
  TO_ATTRIBUTE: 'to_attribute',
  /** A examiner */
  TO_REVIEWED: 'to_reviewed',
  /** En cours d'examen */
  REVIEW_IN_PROGRESS: 'review_in_progress',
  /** A statuer OPBM */
  STATUS_OPBM: 'status_opbm',
  /** Retrait total */
  TOTAL_WITHDRAWAL: 'total_withdrawal',
  /** Arrêté Fond Forme */
  REVIEW_STAND_BY: 'review_stand_by',
  /** Retiré */
  REJECTED: 'rejected',
  /** Validé */
  VALIDATED: 'validated',
  /** Publié */
  PUBLISHED: 'published',
  /** Ajourné en attente */
  ADJOURNED_PENDING: 'adjourned_pending',
  /** Publication demandée */
  PUBLISH_REQUESTED: 'publish_requested',
  /** Simplifié en attente */
  SIMPLIFIED_PENDING: 'simplified_pending',
  /** A déchoir */
  TO_DECOMMISSIONED: 'to_decommissioned',
  /** Déchu */
  DECOMMISSIONED: 'decommissioned',
  /** Ajourné hors-délais */
  ADJOURNED_OUT_OF_TIME: 'adjourned_out_of_time',
  /** Errata */
  ERRATA: 'errata',
  /** A rejeter */
  TO_REJECT: 'to_reject'
}

export const STATUS_DEPOSIT_OPTIONS = [
  {
    value: STATUS_DEPOSIT.DRAFT,
    label: 'status_deposit_draft'
  },
  {
    value: STATUS_DEPOSIT.CHECK_ACCEPTABILITY,
    label: 'status_deposit_check_acceptability'
  },
  {
    value: STATUS_DEPOSIT.NOT_ACCEPTED,
    label: 'status_deposit_not_accepted'
  },
  {
    value: STATUS_DEPOSIT.TO_ATTRIBUTE,
    label: 'status_deposit_to_attribute'
  },
  {
    value: STATUS_DEPOSIT.TO_REVIEWED,
    label: 'status_deposit_to_reviewed'
  },
  {
    value: STATUS_DEPOSIT.REVIEW_IN_PROGRESS,
    label: 'status_deposit_review_in_progress'
  },
  {
    value: STATUS_DEPOSIT.STATUS_OPBM,
    label: 'status_deposit_status_opbm'
  },
  {
    value: STATUS_DEPOSIT.TOTAL_WITHDRAWAL,
    label: 'status_deposit_total_withdrawal'
  },
  {
    value: STATUS_DEPOSIT.REVIEW_STAND_BY,
    label: 'status_deposit_review_stand_by'
  },
  {
    value: STATUS_DEPOSIT.REJECTED,
    label: 'status_deposit_rejected'
  },
  {
    value: STATUS_DEPOSIT.VALIDATED,
    label: 'status_deposit_validated'
  },
  {
    value: STATUS_DEPOSIT.PUBLISHED,
    label: 'status_deposit_published'
  },
  {
    value: STATUS_DEPOSIT.ADJOURNED_PENDING,
    label: 'status_deposit_adjourned_pending'
  },
  {
    value: STATUS_DEPOSIT.PUBLISH_REQUESTED,
    label: 'status_deposit_publish_requested'
  },
  {
    value: STATUS_DEPOSIT.SIMPLIFIED_PENDING,
    label: 'status_deposit_simplified_pending'
  },
  {
    value: STATUS_DEPOSIT.TO_DECOMMISSIONED,
    label: 'status_deposit_to_decommissioned'
  },
  {
    value: STATUS_DEPOSIT.DECOMMISSIONED,
    label: 'status_deposit_decommissioned'
  }
]

export const STATUS_SVA_SVR = 'SVA/SVR'
export const STATUS_CORRECTION_LABEL = 'CORRECTION_REQUEST'
export const STATUS_PUBLISH_REQUESTED = 'PUBLISH_REQUESTED'
export const STATUS_ON_BOPI_PUBLISHED_REQUEST = 'ON_BOPI_PUBLISHED_REQUEST'
export const STATUS_NOTIFICATION = 'NOTIFICATION'
export const STATUS_FALLEN = 'FALLEN'
export const STATUS_REJECTED = 'REJECTED'
export const STATUS_WITHDRAWN = 'WITHDRAWN'
export const STATUS_RENOUNCED = 'RENOUNCED'
export const STATUS_CANCELED = 'CANCELED'
export const STATUS_NOT_ACCEPTABLE = 'NOT_ACCEPTABLE'
export const STATUS_EXTENDABLE = 'EXTENDABLE'
export const STATUS_TITLED = 'TITLED'
export const STATUS_PUBLICATION_REQUESTED = 'PUBLICATION_REQUESTED'
export const STATUS_REGISTERED_5_YEARS = 'REGISTERED_5_YEARS'
export const STATUS_BOPI_INSERTION_REQUESTED = 'BOPI_INSERTION_REQUESTED'
export const STATUS_EXTENDABLE_10_YEARS = 'EXTENDABLE_10_YEARS'
export const STATUS_EXTENDABLE_15_YEARS = 'EXTENDABLE_15_YEARS'
export const STATUS_EXTENDABLE_20_YEARS = 'EXTENDABLE_20_YEARS'
export const STATUS_EXTENDABLE_25_YEARS = 'EXTENDABLE_25_YEARS'
export const STATUS_END_OF_RIGHT = 'END_OF_RIGHT'
export const STATUS_ETS_ACCOUNT_REQUEST = 'ETS_ACCOUNT'
export const STATUS_MANAGEABLE_MEMORY = 'MANAGEABLE_MEMORY'
export const STATUS_IN_CLAIM_INSCRIPTION = 'IN_CLAIM_INSCRIPTION'
export const STATUS_IN_CLAIM_PROROGATION = 'IN_CLAIM_PROROGATION'
export const COLOR_REPRODUCTION_IMAGE = 'COULEUR'
export const BLACK_AND_WHITE_REPRODUCTION_IMAGE = 'NB'
export const TO_DETERMINED = 'to_determined'
export const COMPLIANT = 'compliant'
export const NOT_COMPLIANT = 'not_compliant'

// Inscriptions
export const STATUS_SVR_ONE_MONTH_INSCRIPTION = 'svr_one_month_inscription'
export const STATUS_SVR_TWO_WEEKS_INSCRIPTION = 'svr_two_weeks_inscription'
export const STATUS_MANAGEABLE_MEMORY_INSCRIPTION = 'MANAGEABLE_MEMORY_INSCRIPTION'
export const STATUS_PAYMENT_TO_REFUND_INSCRIPTION = 'payment_to_refund_inscription'

// Prorogations
export const STATUS_SVR_ONE_MONTH_PROROGATION = 'svr_one_month_prorogation'
export const STATUS_SVR_TWO_WEEKS_PROROGATION = 'svr_two_weeks_prorgation'
export const STATUS_MANAGEABLE_MEMORY_PROROGATION = 'MANAGEABLE_MEMORY_PROROGATION'
export const STATUS_PAYMENT_TO_REFUND_PROROGATION = 'payment_to_refund_prorogation'

// Relevé de déchéance
export const STATUS_MANAGEABLE_MEMORY_APPEAL = 'MANAGEABLE_MEMORY_APPEAL'
export const STATUS_PAYMENT_TO_REFUND_APPEAL = 'payment_to_refund_appeal'
export const STATUS_SVA_SVR_APPEAL = 'SVA/SVR_APPEAL'

// Document officiel
export const STATUS_MANAGEABLE_MEMORY_DO = 'MANAGEABLE_MEMORY_DO'
export const STATUS_PAYMENT_TO_REFUND_DO = 'payment_to_refund_do'
export const STATUS_SVA_SVR_DO = 'SVA/SVR_DO'

export const DEPOSIT_TYPE_DEPOT_CLASSIQUE = 'DEPOT_CLASSIQUE'
export const DEPOSIT_TYPE_DEPOT_SIMPLIFIE = 'DEPOT_SIMPLIFIE'
export const DEPOSIT_TYPE_DEPOT_AJOURNE = 'DEPOT_AJOURNE'

export const REPRODUCTION_STATE_OPTIONS = [
  {
    value: '',
    label: ''
  },
  {
    value: STATUS_WITHDRAWN,
    label: Message.request_state_withdrawn,
    borderClassName: 'border border-warning',
    textClassName: 'text-warning'
  },
  {
    value: STATUS_REJECTED,
    label: Message.request_state_rejected,
    borderClassName: 'border border-danger',
    textClassName: 'text-danger'
  },
  {
    value: STATUS_PUBLICATION_REQUESTED,
    label: Message.request_state_publication_requested,
    borderClassName: 'border border-success',
    textClassName: 'text-success'
  },
  {
    value: STATUS_REGISTERED_5_YEARS,
    label: Message.request_state_registered_5_years
  },
  {
    value: STATUS_BOPI_INSERTION_REQUESTED,
    label: Message.request_state_BOPI_insertion_requested
  },
  {
    value: STATUS_EXTENDABLE_10_YEARS,
    label: Message.request_state_extended_10_years
  },
  {
    value: STATUS_EXTENDABLE_15_YEARS,
    label: Message.request_state_extended_15_years
  },
  {
    value: STATUS_EXTENDABLE_20_YEARS,
    label: Message.request_state_extended_20_years
  },
  {
    value: STATUS_EXTENDABLE_25_YEARS,
    label: Message.request_state_extended_25_years
  },
  {
    value: STATUS_FALLEN,
    label: Message.request_state_fallen,
    borderClassName: 'border border-danger',
    textClassName: 'text-danger'
  },
  {
    value: STATUS_END_OF_RIGHT,
    label: Message.request_state_end_of_right,
    borderClassName: 'border border-danger',
    textClassName: 'text-danger'
  }
]

export const REPRODUCTION_STATE_OPTIONS_ADD_RECORD = [
  {
    value: STATUS_RENOUNCED,
    label: Message.request_state_renounced,
    borderClassName: 'border border-danger',
    textClassName: 'text-danger'
  },
  {
    value: STATUS_CANCELED,
    label: Message.request_state_canceled,
    borderClassName: 'border border-danger',
    textClassName: 'text-danger'
  },
  {
    value: STATUS_NOT_ACCEPTABLE,
    label: Message.request_state_not_acceptable,
    borderClassName: 'border border-danger',
    textClassName: 'text-danger'
  }
]

export const INTERVENANT_DESTINATAIRE = {
  value: 'recipient',
  label: Message.intervenant_onglet_destinataire
}

export const INTERVENANT_PAYER = {
  value: 'payer',
  label: Message.intervenant_onglet_payer
}

export const INTERVENANT_DEPOSANT = {
  value: 'depositor',
  label: Message.intervenant_onglet_depositor
}

export const INTERVENANT_DECLARANT = {
  value: 'depositorProrogation',
  fieldValue: 'depositor',
  label: Message.intervenant_onglet_depositorProrogation
}

export const INTERVENANT_DEMANDEUR = {
  value: 'depositorApplicant',
  fieldValue: 'depositor',
  label: Message.intervenant_onglet_depositorApplicant
}

export const INTERVENANT_MANDATAIRE = {
  value: 'agent',
  label: Message.intervenant_onglet_mandataire
}

export const INTERVENANT_TITULAIRE = {
  value: 'holders',
  label: Message.intervenant_onglet_titulaires
}

export const INTERVENANT_CO_DEPOSANT = {
  value: 'coDepositors',
  label: Message.intervenant_coDepositors_edit
}

export const INTERVENANT_CO_DECLARANT = {
  value: 'coDepositorsProrogation',
  fieldValue: 'coDepositors',
  label: Message.intervenant_coDepositorsProrogation_edit
}

export const INTERVENANT_CREATEUR = {
  value: 'creators',
  label: Message.intervenant_onglet_createur
}

export const INTERVENANT_SIGNATAIRE = {
  value: 'signatory',
  label: Message.intervenant_onglet_signatory
}

export const INTERVENANT_APPLICANTS = {
  value: 'applicants',
  label: Message.intervenant_applicants
}

export const INTERVENANT_OTHER_APPLICANTS = {
  value: 'otherApplicants',
  label: Message.intervenant_other_applicants
}

export const INTERVENANT_BENEFICIARIES = {
  value: 'beneficiaries',
  label: Message.intervenant_beneficiary
}

export const STATUS_CORRECTION = {
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
  VALIDATED: 'validated',
  REJECTED: 'rejected'
}

const DEPOT_CLASSIQUE = {
  code: 'DEPOT_CLASSIQUE',
  title: 'select_depot_type_classique_title',
  resume: 'select_depot_type_classique_resume'
}

export const DEPOT_AJOURNE = {
  code: 'DEPOT_AJOURNE',
  title: 'select_depot_type_ajourne_title',
  resume: 'select_depot_type_ajourne_resume'
}

export const DEPOT_SIMPLIFIE = {
  code: 'DEPOT_SIMPLIFIE',
  title: 'select_depot_type_simplifie_title',
  resume: 'select_depot_type_simplifie_resume'
}

export const DEPOSIT_TYPES = [
  DEPOT_CLASSIQUE,
  DEPOT_AJOURNE,
  DEPOT_SIMPLIFIE
]

export const DEPOSIT_DIVISION_STATUS = {
  TO_VALIDATE: 'TO_VALIDATE',
  APPLIED: 'APPLIED'
}

export const DEPOSIT_TYPES_OPTIONS = [
  {
    value: DEPOSIT_TYPE_DEPOT_CLASSIQUE,
    label: Message.select_depot_type_classique_title
  },
  {
    value: DEPOSIT_TYPE_DEPOT_AJOURNE,
    label: Message.select_depot_type_ajourne_title
  },
  {
    value: DEPOSIT_TYPE_DEPOT_SIMPLIFIE,
    label: Message.select_depot_type_simplifie_title
  }
]

export const EXTENDED_TRANSACTION_STATES = [
  STATUS_EXTENDABLE_10_YEARS,
  STATUS_EXTENDABLE_15_YEARS,
  STATUS_EXTENDABLE_20_YEARS,
  STATUS_EXTENDABLE_25_YEARS
]
