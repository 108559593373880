import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import {
  DEPOSIT_FIELD_STATUS_RESET,
  DEPOSIT_FIELD_STATUS_UPDATE,
  DEPOSIT_REMOVE,
  DEPOSIT_UPDATE,
  DepositFieldStatusStoreAction,
  DepositStoreAction
} from './depositStoreTypes'
import { DEFAULT_INTERVENANT, Deposit } from '@inpi-dm/components'

const initialDepositState: Deposit = {
  depositor: DEFAULT_INTERVENANT,
  coDepositors: [],
  recipient: DEFAULT_INTERVENANT,
  models: []
}
/* Contient les erreurs liées au formulaire de dépôt */
const initialDepositStatusState = {}

export function reducerDepositState (state = initialDepositState, action: DepositStoreAction): Deposit {
  switch (action.type) {
    case DEPOSIT_UPDATE:
      return {
        ...state,
        ...action.deposit
      }
    case DEPOSIT_REMOVE:
      return initialDepositState
    default:
      return state
  }
}

export function reducerDepositFieldStatusState (state = initialDepositStatusState, action: DepositFieldStatusStoreAction): DepositFieldStatus {
  switch (action.type) {
    case DEPOSIT_FIELD_STATUS_UPDATE:
      return {
        ...state,
        ...action.fieldStatus
      }
    case DEPOSIT_FIELD_STATUS_RESET:
      return initialDepositStatusState
    default:
      return state
  }
}
