import React, { FC, MutableRefObject, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  TextArea,
  TextField,
  SubmitButton,
  MultipleRadioField, isFilled, FieldStatus, ReproductionLightDeposit
} from '@inpi-dm/components'
import { BLACK_AND_WHITE, COLORS } from '../../../../../constants/DepositConstants'
import { RootStateOrAny, useSelector } from 'react-redux'
import { DEPOT_SIMPLIFIE } from '@inpi-dm/components/src/constants/DepositConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

interface ReproductionFormProps {
    setAccordionIndex: (index: number) => void,
    indexModel: number,
    index: number,
    isLastReproduction: boolean,
    validatorFormReproduction: (index: number) => boolean,
    repro: ReproductionLightDeposit,
    handleChangeReproduction: (index: number, elemToChange: object) => void,
    fieldStatus: FieldStatus,
    reproRefs: MutableRefObject<any>[],
    accordionIndex: number
}

const ReproductionForm: FC<ReproductionFormProps> = ({
  setAccordionIndex,
  indexModel,
  index,
  isLastReproduction,
  validatorFormReproduction,
  repro,
  handleChangeReproduction,
  fieldStatus,
  reproRefs,
  accordionIndex
}) => {
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)

  // Focus sur le champ 'Intitulé de la reproduction' à chaque fois que l'on appuie sur 'Reproduction suivante'
  useEffect(() => {
    if (reproRefs.current.length > 0 && reproRefs.current[accordionIndex] && reproRefs.current[accordionIndex].firstChild.children &&
      reproRefs.current[accordionIndex].firstChild.children[1] && reproRefs.current[accordionIndex].firstChild.children[1].firstChild &&
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild &&
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild.firstChild &&
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild.firstChild.firstChild &&
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild.firstChild.firstChild.firstChild &&
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild.firstChild.firstChild.firstChild.children &&
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild.firstChild.firstChild.firstChild.children[1]) {
      reproRefs.current[accordionIndex].firstChild.children[1].firstChild.firstChild.firstChild.firstChild.firstChild.children[1].focus()
    }
  }, [accordionIndex])

  const changeRepro = () => {
    // Passer à la dernière reproduction si tous les champs requis sont remplis, sinon passe à la suivante
    if (deposit?.depositType !== DEPOT_SIMPLIFIE.code && deposit.models[indexModel] &&
            deposit.models[indexModel].reproductions[index] && deposit.models[indexModel].reproductions[index + 1] &&
            isFilled(deposit.models[indexModel].reproductions[index + 1].label) &&
            isFilled(deposit.models[indexModel].reproductions[index + 1].color)) {
      setAccordionIndex(deposit.models[indexModel].reproductions.length)
    } else if (deposit.models[indexModel] && deposit?.depositType === DEPOT_SIMPLIFIE.code) {
      setAccordionIndex(deposit.models[indexModel].reproductions.length)
    } else {
      setAccordionIndex(index + 1)
    }
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <TextField
            inputId={`reproductionLabel-${indexModel}-${index}`}
            label={<FormattedMessage id='reproductions_form_label' />}
            value={repro.label}
            fieldStatus={fieldStatus}
            nameFieldStatus='reproductionLabel'
            onChange={(e) => {
              const { value } = e.target
              handleChangeReproduction(index, { label: value })
            }}
            maxLength='100'
            required={deposit?.depositType !== DEPOT_SIMPLIFIE.code}
            infoHelp={<FormattedMessage id='reproductions_subtitle' />}
          />
          <div className='justify-content-center align-items-center mt-4'>
            <MultipleRadioField
              inputId={`reproductionColor-${indexModel}-${index}`}
              fieldStatus={fieldStatus}
              nameFieldStatus='reproductionColor'
              radio={[
                {
                  label: <FormattedMessage id='reproductions_form_radio_colors' />,
                  value: COLORS
                },
                {
                  label: <FormattedMessage id='reproductions_form_radio_no_colors' />,
                  value: BLACK_AND_WHITE
                }
              ]}
              value={repro.color}
              onChange={(e) => {
                const { value } = e.target
                handleChangeReproduction(index, { color: value })
              }}
            />
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <TextArea
            inputId={`reproductionDescription-${indexModel}-${index}`}
            value={repro.description}
            onChange={(e) => {
              const { value } = e.target
              handleChangeReproduction(index, { description: value })
            }}
            label={<FormattedMessage id='reproductions_form_description' />}
            rows={4}
            maxLength='500'
            infoHelp={<FormattedMessage id='reproductions_form_description_info' />}
          />
        </div>
      </div>
      {
        !isLastReproduction &&
          <div className='row justify-content-end'>
            <div className='col-4'>
              <SubmitButton
                className='btn-block btn-outline-primary'
                onClick={() => {
                  if (validatorFormReproduction(index)) {
                    changeRepro()
                  }
                }}
              >
                <FormattedMessage id='reproductions_form_next' />
                <FontAwesomeIcon icon={faArrowRight} />
              </SubmitButton>
            </div>
          </div>
      }
    </div>

  )
}

export default ReproductionForm
