
import React, { FC, useEffect, useState } from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  APPEAL_TYPE,
  AutocompleteResult,
  DEFAULT_INTERVENANT,
  Deposit,
  DepositIntervenant,
  EventType,
  RenderRecipientForm,
  Transaction
} from '@inpi-dm/components'
import IntervenantService from '../../services/transaction/IntervenantService'
import InternalReferenceField from '../internalReference/InternalReferenceField'
import AddressApiService from '../../services/content/AddressApiService'
import ContentService from '../../services/content/ContentService'
import { InscriptionFieldStatus } from '../../interfaces/InscriptionInterfaces'
import { DepositFieldStatus } from '../../interfaces/DepositInterfaces'
import HeaderIntervenant from './HeaderIntervenant'
import { ProrogationFieldStatus } from '../../interfaces/ProrogationInterfaces'
import StoreService from '../../services/StoreService'

interface RecipientFormProps extends WrappedComponentProps {
  object : Transaction|Deposit,
  fieldStatus: InscriptionFieldStatus| DepositFieldStatus | ProrogationFieldStatus,
  procedureType: string,
  findIntervenantsList: string[];
  defaultIntervenantlist?: DepositIntervenant[]
}

const RecipientForm: FC<RecipientFormProps> = ({
  object,
  fieldStatus,
  procedureType,
  findIntervenantsList,
  intl,
  defaultIntervenantlist = []
}) => {
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [idCurrentUser, setIdCurrentUser] = useState((object.recipient?.idUser) || -1)
  const [intervenants, setIntervenants] = useState<AutocompleteResult<DepositIntervenant>[]>([])

  useEffect(() => {
    // On filtre les intervenants dans la transaction du store qui ne sont pas aptes à être copiés
    const filteredIntervenantsTransaction = IntervenantService.findIntervenants(object, findIntervenantsList)
      .filter((intervenant) => (intervenant.firstname || intervenant.lastname || intervenant.companyName) && intervenant.address)

    // Récupération des intervenants a copier
    const listIntervenants = IntervenantService.removeDuplicateIntervenants([...defaultIntervenantlist, ...filteredIntervenantsTransaction])
      .map(intervenant => ({
        label: `${intervenant.numNat ? `${intervenant.numNat} - ` : ''}${IntervenantService.getName(intervenant)}`,
        value: intervenant
      }))
    setIntervenants(listIntervenants)

    if (object.recipient && !object.recipient.email) {
      const updateRecipient = {
        ...object.recipient,
        email: user.email
      }
      StoreService.changeStore(procedureType, { recipient: updateRecipient })
    }
  }, [object])

  /**
   * Enregistrement des données dans le store à la saisie
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target

    const updatedRecipient = {
      ...object.recipient,
      [name]: value
    }

    StoreService.changeStore(procedureType, { recipient: updatedRecipient })
  }

  /**
   * Remplissage du formulaire avec les informations de l'utilisateur connecté
   */
  const handleSelfRecipient = () => {
    if (user) {
      IntervenantService.buildIntervenantFromUser(user).then(intervenantFromUser => {
        const recipient = {
          ...intervenantFromUser,
          id: object.recipient?.id,
          firstname: user.firstname,
          lastname: user.lastname,
          civilityCode: user.civilityCode
        }
        // Même si c'est une personne morale, on ne stocke pas le siren
        delete recipient.siren
        StoreService.changeStore(procedureType, { recipient: recipient })
        setIdCurrentUser(user.id)
      })
    }
  }

  /**
   * Réinitialisation des champs du formulaire
   */
  const handleResetRecipient = () => {
    const updateRecipient = {
      ...DEFAULT_INTERVENANT,
      email: user.email
    }
    StoreService.changeStore(procedureType, { recipient: updateRecipient })
    setIdCurrentUser(-1)
  }

  /**
   * Copie d'un intervenant dans le correspondant
   */
  const handleSelectIntervenant = (intervenant: DepositIntervenant) => {
    if (intervenant) {
      IntervenantService.validateCountryOfContributor(intervenant).then(newIntervenant => {
        const updateRecipient = {
          ...newIntervenant,
          email: user.email,
          id: object.recipient?.id,
          address: {
            ...newIntervenant.address
          }
        }
        StoreService.changeStore(procedureType, { recipient: updateRecipient })
        setIdCurrentUser(intervenant.idUser || -1)
      })
    }
  }

  return (
    <div className='form-intervenant is-validated'>
      <div className='row'>
        <HeaderIntervenant procedureType={procedureType} />
        <InternalReferenceField
          value={object.internalReference}
          procedureType={procedureType}
          className='col-4 mb-4'
        />
      </div>
      <RenderRecipientForm
        intervenants={intervenants}
        searchAddress={AddressApiService.searchAddress}
        getCountries={ContentService.getCountries}
        handleSelectIntervenant={handleSelectIntervenant}
        depositRecipient={{ ...object.recipient }}
        idCurrentUser={idCurrentUser}
        handleSelfRecipient={handleSelfRecipient}
        handleResetRecipient={handleResetRecipient}
        handleChange={handleChange}
        fieldStatus={fieldStatus.recipient}
        foDisplay
        intl={intl}
        checkIsRecipientNeeded={procedureType === APPEAL_TYPE.value}
      />
    </div>
  )
}

export default injectIntl(RecipientForm)
