import React, { FC, useEffect, useState } from 'react'
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { containsErrors, DEPOSIT_TYPE, ErrorField, LocarnoVersion, ModelDeposit, SubmitButton } from '@inpi-dm/components'
import ModelForm from './modelForm/ModelForm'
import ModelModalMassImport from './modelForm/ModelModalMassImport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import LocarnoService from '../../../../services/content/LocarnoService'
import DepositService from '../../../../services/transaction/DepositService'
import InternalReferenceField from '../../../internalReference/InternalReferenceField'
import { storeDepositUpdate } from '../../../../store/deposit/depositActions'
import OverviewModels from '../overview/OverviewModels'
import TransactionService from '../../../../services/transaction/TransactionService'

interface ModelsFormDepositProps {
  setNeedConfirmation: (needConfirmation: boolean) => void,
}

const ModelsFormDeposit: FC<ModelsFormDepositProps> = ({ setNeedConfirmation }) => {
  const [nbCurrent, setNbCurrent] = useState(0)
  const [showModelForm, setShowModelForm] = useState(false)
  const [showModelMassImport, setShowModelMassImport] = useState(false)

  const [isModelChange, setIsModelChange] = useState(false)
  const [progress, setProgress] = useState(0)

  const [models, setModels] = useState<ModelDeposit[]>([])
  const [versionLocarno, setVersionLocarno] = useState<LocarnoVersion>({})

  const [nbRepros, setNbRepros] = useState(0)

  const dispatch = useDispatch()
  const deposit = useSelector((state : RootStateOrAny) => state.deposit)

  const fieldStatus = useSelector((state : RootStateOrAny) => state.depositFieldStatus)

  const intl = useIntl()

  useEffect(() => {
    // Récupération des catégories principales de Locarno
    LocarnoService.getClassification().then(setVersionLocarno)
  }, [])

  useEffect(() => setNeedConfirmation(showModelForm), [showModelForm])

  useEffect(() => {
    const countNbRepros = models.reduce((total, currentModel) =>
      currentModel.reproductions ? total + currentModel.reproductions.length : total
    , 0)
    setNbRepros(countNbRepros)
  }, [models])

  /**
   * Récupération des informations dans le back
   */
  const fetchData = () => {
    return TransactionService.getTransaction(deposit.idTransaction).then(depositOutput => {
      setModels(depositOutput.file.models || [])
      setNbCurrent(depositOutput.file.models?.length || 0)
      setShowModelForm(false)
      setIsModelChange(isModelChange => !isModelChange)
      // Mise à jour du store
      dispatch(storeDepositUpdate({
        models: depositOutput.file.models
      }))
    })
  }

  /**
   * Initialisation des modeles
   */
  useEffect(() => {
    fetchData()
  }, [])

  /**
   * Modifie le modele actuellement affiché
   * @param newCurrent
   */
  const changeModel = (newCurrent : number) => {
    setNbCurrent(newCurrent)
    setShowModelForm(true)
  }

  /**
   * Trouve la dernière position des modèles du dossier
   * @returns {number}
   */
  const findLastPosition = (): number => {
    let lastPosition = -1
    if (models.length > 0) {
      lastPosition = models.reduce((previous, current) => {
        return (previous.position > current.position) ? previous : current
      }).position
    }

    return lastPosition
  }

  /**
   * Modification d'un modèle déjà dans le back
   * @param model
   */
  const updateModel = async (model: ModelDeposit) => {
    // Modifier les informations du modèle dans le back
    await DepositService.updateModelToDeposit(deposit.idTransaction, { ...model, position: models[nbCurrent].position })

    if (model.reproductions) {
      for (let i = 0; i < model.reproductions.length; i++) {
        if (model.reproductions[i]) {
          // On vérifie si l'on doit faire un POST ou un PUT
          const internalName = model.reproductions[i].idInternalName || model.reproductions[i].file?.internalName
          if (internalName) {
            // on modifie une reproduction appartenant déjà au model
            await DepositService.updateReproduction(deposit.idTransaction, model.id, internalName, i, {
              label: model.reproductions[i].label,
              color: model.reproductions[i].color,
              description: model.reproductions[i].description
            })
          } else {
            // on ajoute une reproduction au model existant
            const reproductionToSend = { ...model.reproductions[i], position: i }
            await DepositService.addReproductionToModel(deposit.idTransaction, model.id, reproductionToSend)
          }
        }
      }
    }
  }

  /**
   * Création d'un modèle dans le back
   * @param model
   * @param position
   */
  const createModel = async (model: ModelDeposit) => {
    const position = findLastPosition() + 1
    // Création dans le back du model
    const result = await DepositService.addModelToDeposit(deposit.idTransaction, { ...model, position: position })

    const idModel = result.file.models.find(element => element.position === position).id
    // Enregistrement dans le back de ses reproductions
    if (model.reproductions) {
      for (const reproduction of model.reproductions) {
        await DepositService.addReproductionToModel(deposit.idTransaction, idModel, reproduction)
      }
    }
  }

  /**
   * A la validation d'un modèle
   */
  const submitModel = (model: ModelDeposit) => {
    // si on a un ID alors on est dans le cas de la modification et non de la création
    if (model.id) {
      return updateModel(model).then(() => {
        return fetchData()
      })
    } else {
      return createModel(model).then(() => {
        return fetchData()
      })
    }
  }

  /**
   * Suppression d'un modele
   * @param deleteId
   */
  const deleteModel = (deleteId : number) => {
    if (models[deleteId].id) {
      return DepositService.deleteModel(deposit.idTransaction, models[deleteId].id).then(() => {
        return fetchData()
      })
    } else {
      return null
    }
  }

  /**
   * Création de x reproductions à partir d'un tableau de x modèles
   * @param modelsImport
   */
  const createReproductionsMassiveImport = async (modelsImport: ModelDeposit[]) => {
    const position = findLastPosition() + 1
    for (let i = 0; i < modelsImport.length; i++) {
      const m = { ...modelsImport[i], position: i + position }
      await DepositService.addReproductionFromMassiveImport(deposit.idTransaction, m).then(() =>
        setProgress(i)
      )
    }
  }

  /**
   * Importer tous les modeles de la popup
   * @param modelsImport
   */
  const importModels = (modelsImport: ModelDeposit[]) => {
    return createReproductionsMassiveImport(modelsImport).then(() => {
      return fetchData().then(() => {
        setShowModelMassImport(false)
      })
    })
  }

  return (
    <div>
      <div className='d-flex'>
        <header className='col-8 align-self-center'>
          <h1><FormattedMessage id='models_deposit_form_title' /></h1>
          <span className='small'>
            <FormattedMessage
              id='models_deposit_form_subtitle'
              values={{ a: (...chunks) => (<a href={process.env.REACT_APP_URL_HELP_MODELS} target='_blank' rel='noopener noreferrer'>{chunks}</a>) }}
            />
          </span>
        </header>
        <InternalReferenceField
          value={deposit.internalReference}
          procedureType={DEPOSIT_TYPE.value}
          className='col-4 align-self-center'
        />
      </div>
      <div className='d-flex flex-row justify-content-end'>
        <SubmitButton
          className='btn-link text-primary'
          onClick={() => {
            setNbCurrent(models.length)
            setShowModelForm(false)
            setShowModelMassImport(true)
          }}
        >
          <div data-toggle='tooltip' title={intl.formatMessage({ id: 'mass_import_modeles_description' })}>
            <FontAwesomeIcon icon={faFolderPlus} className='mr-2' /><FormattedMessage id='mass_import_modeles' />
          </div>
        </SubmitButton>
        <ModelModalMassImport
          show={showModelMassImport} handleClose={() => setShowModelMassImport(false)}
          onClick={importModels} versionLocarno={versionLocarno} progress={progress}
          nbRepros={nbRepros} setProgress={setProgress}
        />
      </div>
      <div className='mb-3 list-group list-group-flush'>
        {
          nbCurrent > 0 &&
            <OverviewModels
              depositId={deposit.idTransaction}
              models={models}
              priorities={deposit.priorities}
              hasReproRegularization={false}
              changeModel={changeModel}
              deleteModel={deleteModel}
              fieldStatus={fieldStatus}
              nbCurrent={nbCurrent}
              isBeforeForm
              transaction={deposit}
            />
        }
        {
          showModelForm &&
            <ModelForm
              changeModel={isModelChange}
              key={nbCurrent}
              index={nbCurrent}
              model={models[nbCurrent]}
              submitModel={submitModel}
              models={models}
              onCancelFormModel={() => deleteModel(nbCurrent)}
              fieldStatus={fieldStatus.models && fieldStatus.models[nbCurrent]}
              versionLocarno={versionLocarno}
              nbRepros={nbRepros}
            />
        }
        {
          models.length > nbCurrent + 1 &&
            <OverviewModels
              depositId={deposit.idTransaction}
              models={models}
              priorities={deposit.priorities}
              hasReproRegularization={false}
              changeModel={changeModel}
              deleteModel={deleteModel}
              nbCurrent={nbCurrent}
              isBeforeForm={false}
              transaction={deposit}
            />
        }
      </div>
      {
        !showModelForm &&
          <div className='col-4'>
            <SubmitButton
              className='btn-block btn-outline-primary'
              onClick={() => setShowModelForm(true)}
            >
              <FormattedMessage id='button_add_new_model' />
            </SubmitButton>
          </div>
      }
      {
        fieldStatus.models && fieldStatus.models.length > 0 && fieldStatus.models[0] && containsErrors(fieldStatus.models[0]) &&
          <ErrorField message={fieldStatus.models[0].modelDesignation} />
      }
    </div>
  )
}

export default ModelsFormDeposit
